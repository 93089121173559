import NiceModal, { useModal } from '@ebay/nice-modal-react'

import ModalContainer from 'components/ModalContainer'
import { useCreateRun } from 'util/mutations'
import { useState } from 'react'
import { CheckIcon, TruckIcon, UserIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { Button, Card, Input } from 'components/gsys-ui'

const AddRunModal = NiceModal.create(({ runId }) => {
  const modal = useModal()
  const [isSubmitting, setSubmitting] = useState(false)
  const [errorText, setErrorText] = useState(null)
  const [inputValue, setInputValue] = useState('')
  const mutation = useCreateRun()

  const handleSubmit = async (res) => {
    setSubmitting(true)

    try {
      await mutation.mutateAsync({ Name: inputValue })
    } catch (e) {
      console.log(e)
    }

    modal.remove()
  }

  return (
    <ModalContainer>
      <Card className="!w-[350px]">
        <Card.Title Icon={TruckIcon}>Add a schedule</Card.Title>
        <Card.Body>
          <div className="space-y-2">
            {errorText && <div className="text-red-500">{JSON.stringify(errorText)}</div>}
            <div>
              <Input
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                placeholder="Schedule name"
              />
            </div>
            <div className="flex justify-end space-x-2">
              <Button Icon={XMarkIcon} variant="white" onClick={modal.remove}>
                Cancel
              </Button>
              <Button
                Icon={CheckIcon}
                onClick={handleSubmit}
                variant="green"
                loading={isSubmitting}
              >
                Submit
              </Button>
            </div>
          </div>
        </Card.Body>
      </Card>
    </ModalContainer>
  )
})

export default AddRunModal
