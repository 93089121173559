import NiceModal from '@ebay/nice-modal-react'
import {
  Cog8ToothIcon,
  ExclamationCircleIcon,
  ListBulletIcon,
  MapIcon,
  QueueListIcon
} from '@heroicons/react/24/outline'
import clsx from 'clsx'
import { Badge, Button, Card, DraggableTable, Input, MultiButton } from 'components/gsys-ui'
import moment from 'moment-timezone'
import { forwardRef, useContext, useMemo, useRef, useState } from 'react'
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels'
import { useInvoices, useSettings, useTrips } from 'util/queries'
import {
  DndContext,
  DragOverlay,
  pointerWithin,
  useDroppable,
  useSensor,
  useSensors
} from '@dnd-kit/core'
import { snapCenterToCursor } from '@dnd-kit/modifiers'
import { useMoveInvoice } from 'util/mutations'
import { groupByKey } from 'util/helpers'
import { MouseSensor, TouchSensor } from 'util/Sensors'
import { customPointerWithin } from 'util/customPointerWithin'
import { GlobalContext } from 'context/GlobalContext'

moment.tz.setDefault('Europe/London')

const coloursDef = [
  { mins: -20, colour: '#0ea5e9' },
  { mins: -10, colour: '#7dd3fc' },
  { mins: 0, colour: '#fb923c' },
  { mins: 5, colour: '#f87171' },
  { mins: 10, colour: '#dc2626' }
]

// in dev I called van departures with their drops "trips",
// schedules of future deperatures "runs",
// and the patterns of days and times within a schedule "schedules"

// this was the wrong terminology but the variables are all named that way.

// a collection of drops assigned to a van is now called a "run"
// the schedules of future departures are called "schedules"
// and the day/time patterns inside schedules are called "patterns".

// recommend consistency with the existing code here but in the UI make sure to use the right terms.

const Assignment = () => {
  const [tripSearch, setTripSearch] = useState('')
  const mouseSensor = useSensor(MouseSensor)
  const touchSensor = useSensor(TouchSensor)
  const sensors = useSensors(mouseSensor, touchSensor)
  const mutation = useMoveInvoice()
  const [activeInv, setActiveInv] = useState(null)

  const handleDragStart = (e) => {
    setActiveInv(e.active)
  }

  const handleDragEnd = async (e) => {
    if (!e.over) return
    console.log(e.over.id, e.active.data.current.from)
    if (e.over.id === e.active.data.current.from) return

    if (e.over.id === 'unallocated' || e.over.id === 'frozen') {
      await mutation.mutateAsync({ docNo: activeInv.id, status: e.over.id, destTrip: null })
    } else {
      await mutation.mutateAsync({ docNo: activeInv.id, status: 'allocated', destTrip: e.over.id })
    }

    setActiveInv(null)
  }

  return (
    <DndContext
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      autoScroll={false}
      collisionDetection={customPointerWithin}
      sensors={sensors}
    >
      <div className="w-full h-full">
        <PanelGroup autoSaveId="assignment-v" direction="vertical">
          <Panel defaultSize={66} className="relative">
            <div className="overflow-y-scroll absolute inset-0">
              <div className="sticky top-0 right-0 left-0 z-20">
                <TripsToolbar search={tripSearch} setSearch={setTripSearch} />
              </div>
              <TripsList activeInv={activeInv} tripSearch={tripSearch} />
            </div>
          </Panel>
          <ResizeHandle direction="vertical" />
          <Panel defaultSize={34}>
            <PanelGroup autoSaveId="assignment-h" direction="horizontal">
              <Panel defaultSize={50} className="relative">
                <div className="absolute inset-0">
                  <UnallocatedCard activeInv={activeInv} />
                </div>
              </Panel>
              <ResizeHandle direction="horizontal" />
              <Panel defaultSize={50} className="relative">
                <div className="absolute inset-0">
                  <FrozenCard activeInv={activeInv} />
                </div>
              </Panel>
            </PanelGroup>
          </Panel>
        </PanelGroup>
      </div>
      <DragOverlay modifiers={[snapCenterToCursor]} dropAnimation={null}>
        {activeInv && (
          <div className="inline-flex items-center p-1 bg-white rounded-md border-2 border border-blue-400 drop-shadow-lg cursor-grabbing">
            <QueueListIcon className="mr-1 w-5 h-5" />
            <span className="mr-2 font-bold">DOC</span> {activeInv.id}
          </div>
        )}
      </DragOverlay>
    </DndContext>
  )
}

const ResizeHandle = ({ direction }) => {
  return (
    <PanelResizeHandle
      className={clsx(
        'data-[resize-handle-active]:bg-opacity-10 transition-colors bg-black bg-opacity-5',
        direction === 'vertical' && 'h-1.5 border-t border-t-1 border-t-gray-300 drop-shadow-md',
        direction === 'horizontal' && 'w-1.5'
      )}
    ></PanelResizeHandle>
  )
}

const TripsToolbar = ({ search, setSearch }) => {
  const { branch } = useContext(GlobalContext)

  const handleNewRunClick = () => {
    NiceModal.show('assignmentAddRunModal', { branch })
  }

  return (
    <div className="flex items-center p-2 space-x-2 bg-gray-100 border-b border-gray-300 shadow">
      <Button onClick={handleNewRunClick}>Create a run</Button>
      <div className="w-48">
        <Input
          className="!py-0.5 !h-8"
          placeholder="Type to search..."
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
    </div>
  )
}

const TripsList = forwardRef(({ activeInv, tripSearch }, ref) => {
  const { isLoading, isError, error, data } = useTrips()

  const dataWithSearchText = useMemo(() => {
    if (!data) return []
    const dataGrouped = groupByKey('RunId', data)
    let dataFiltered = []

    for (const run of Object.values(dataGrouped)) {
      const tripsSorted = run.sort((a, b) =>
        moment(a.DepartureTime).isAfter(moment(b.DepartureTime))
      )
      let didFutureTrip = false

      for (const trip of tripsSorted) {
        const minDiff = moment(trip.DepartureTime).diff(moment(), 'minutes')

        if (minDiff <= 0) {
          dataFiltered.push(trip)
          continue
        }

        if (!didFutureTrip && minDiff > 0) {
          didFutureTrip = true
          dataFiltered.push(trip)
          continue
        }

        if (minDiff < 15) {
          dataFiltered.push(trip)
          continue
        }
      }
    }

    const dataWithSearchText = dataFiltered.map((d) => {
      const searchText = `${d.RunName} ${d.Invoices.map(
        (i) => `${i.Acct} ${i.Branch} ${i.Document} ${i.Name}`
      ).join(' ')}`.toLowerCase()

      return { ...d, SearchText: searchText }
    })

    return dataWithSearchText
  }, [JSON.stringify(data)])

  const dataSearched = useMemo(() => {
    return dataWithSearchText.filter((d) => {
      if (d.SearchText.includes(tripSearch.toLowerCase())) return true
      return false
    })
  }, [JSON.stringify(dataWithSearchText), tripSearch])

  const dataSorted = dataSearched.sort((a, b) =>
    moment(a.DepartureTime).isAfter(moment(b.DepartureTime))
  )

  return (
    <div ref={ref} class="grid overflow-y-scroll grid-cols-4 gap-1 p-2">
      {dataSorted.map((el, ind) => (
        <TripCard key={ind} data={el} activeInv={activeInv} />
      ))}
    </div>
  )
})

const Droppable = ({ id, children, activeInv, isRounded = true }) => {
  const { isOver, setNodeRef } = useDroppable({ id })
  const isHighlighted = isOver && activeInv && activeInv.data.current.from !== id

  return (
    <div ref={setNodeRef} className="relative h-full">
      {children}
      <div
        className={clsx(
          'z-50 absolute inset-0 shadow-droppable-none transition-shadow pointer-events-none',
          isRounded && 'rounded-md',
          isHighlighted && 'shadow-droppable'
        )}
      />
    </div>
  )
}

// This is the updated TripCard component with a clickable title bar
// that opens a modal with trip information

const TripCard = ({ data, activeInv }) => {
  const { isLoading, isError, error, data: settings } = useSettings()
  const settingsKey = settings && settings.filter((el) => el.Key === 'assignmentTripColours').pop()
  if (!settings) return null
  const colours = settingsKey ? JSON.parse(settingsKey.Value) : coloursDef
  //data && console.info('data', data)
  let colourToDisplay = null
  const timeMins = moment().diff(moment(data.DepartureTime), 'minutes')

  for (const el of colours) {
    if (timeMins >= el.mins) {
      colourToDisplay = el.colour
    }
  }

  if (colourToDisplay === null) {
    const min = colours.reduce(function (prev, current) {
      return prev && prev.mins < current.mins ? prev : current
    })

    colourToDisplay = min.colour
  }

  const invoicesSorted = data.Invoices.sort((a, b) => a.Document.localeCompare(b.Document)).sort(
    (a, b) => a.Name.localeCompare(b.Name)
  )

  //console.table(invoicesSorted)

  // New function to handle the title bar click
  const handleTitleClick = () => {
    NiceModal.show('tripModal', { tripData: data })
  }

  return (
    <Droppable activeInv={activeInv} id={data._id}>
      <Card className="flex relative flex-col h-64">
        {/* Updated title bar with cursor-pointer and onClick handler */}
        <div
          className="flex flex-none px-1 w-full rounded-t-md border-b-2 border-b transition-all cursor-pointer hover:brightness-95"
          style={{ background: colourToDisplay }}
          onClick={handleTitleClick}
        >
          <div className="flex-1 py-0.5 truncate">{data.RunName}</div>
          <div className="flex flex-none items-center pl-1.5 space-x-1">
            <div>
              <span className="text-sm font-bold">DEPARTS</span>{' '}
              {moment(data.DepartureTime).format('HH:mm')}
            </div>
            {/* Added stopPropagation to prevent the map button from triggering the modal */}
            <Button
              variant="rounded"
              tiny
              onClick={(e) => {
                e.stopPropagation()
                NiceModal.show('assignmentMapModal', { tripId: data._id })
              }}
              Icon={MapIcon}
            />
          </div>
        </div>
        <div className="flex flex-none w-full border-b-2">
          <div className="flex-1 p-1 border-r border-r-1 border-r-gray-300">
            <div className="text-xs font-bold leading-tight mb-[-3px]">RUN VALUE</div>
            <div>
              £
              {data.Invoices.reduce((acc, cur) => {
                const value = parseFloat(cur.Goods)
                return acc + (isNaN(value) ? 0 : value)
              }, 0).toLocaleString('en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
            </div>
          </div>
          <div className="flex-1 p-1 border-r border-r-1 border-r-gray-300">
            <div className="text-xs font-bold leading-tight mb-[-3px]">DROPS</div>
            <div>{data?.Accts?.length}</div>
          </div>
          <div className="flex-1 p-1 border-r border-r-1 border-r-gray-300">
            <div className="text-xs font-bold leading-tight mb-[-3px]">DOCS</div>
            <div>{data.Invoices.length}</div>
          </div>
          <div className="flex-1 p-1">
            <div className="text-xs font-bold leading-tight mb-[-3px]">ITEMS</div>
            <div>{data.LinesQty - 1}</div>
          </div>
        </div>
        <div className="overflow-scroll flex-1">
          <InvTableReduced data={invoicesSorted} dragData={{ from: data._id }} />
        </div>
      </Card>
    </Droppable>
  )
}

const UnallocatedCard = ({ activeInv }) => {
  const [search, setSearch] = useState('')
  const [invMulti, setInvMulti] = useState('today')
  const { isLoading, isError, error, data } = useInvoices('unallocated')

  if (isLoading || !data) return null
  if (isError) {
    console.error('Error details:', error)
    return <div>Error: {error.message || 'Unknown error'}</div>
  }

  const dataFiltered =
    invMulti === 'today'
      ? data.filter((row) =>
        moment.tz(row.DateTime, 'Etc/UTC').isAfter(moment.tz('Etc/UTC').startOf('day'))
      )
      : data
  const dataSearched = dataFiltered.filter((row) =>
    `${row.Document} ${row.Acct} ${row.Name}`.toLowerCase().includes(search.toLowerCase().trim())
  )

  return (
    <Droppable id="unallocated" activeInv={activeInv} isRounded={false}>
      <Card className="flex relative flex-col h-full !rounded-none">
        <div className="flex flex-none justify-between items-center px-2 py-1 w-full truncate border-b border-b-1 height-[45px]">
          <div>Unallocated Invoices</div>
          <div className="flex items-center space-x-2">
            <div className="">
              <MultiButton
                options={[
                  {
                    label: 'Today',
                    value: 'today'
                  },
                  {
                    label: 'All',
                    value: 'all'
                  }
                ]}
                value={invMulti}
                onChange={setInvMulti}
              />
            </div>
            <div className="w-48">
              <Input
                className="!py-0.5 !h-8"
                placeholder="Type to search..."
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
            <Button
              variant="rounded"
              small
              onClick={() => NiceModal.show('assignmentSettingsModal')}
              Icon={Cog8ToothIcon}
            />
          </div>
        </div>
        <InvTable data={dataSearched} dragData={{ from: 'unallocated' }} />
      </Card>
    </Droppable>
  )
}

const FrozenCard = ({ activeInv }) => {
  const [search, setSearch] = useState('')
  const { isLoading, isError, error, data } = useInvoices('frozen')
  //const isHighlighted = isOver && activeInv && activeInv.data.current.from !== 'frozen'

  if (isLoading || !data) return null
  if (isError) {
    console.error('Error details:', error)
    return <div>Error: {error.message || 'Unknown error'}</div>
  }

  const dataFiltered = data
    ? data.filter((row) =>
      `${row.Document} ${row.Acct} ${row.Name}`
        .toLowerCase()
        .includes(search.toLowerCase().trim())
    )
    : []

  return (
    <Droppable activeInv={activeInv} id="frozen">
      <Card className="flex relative flex-col h-full !rounded-none">
        <div className="flex flex-none justify-between items-center px-2 py-1 w-full truncate border-b border-b-1">
          <div className="flex items-center space-x-1">
            {!!data.length && (
              <Badge variant="red" rounded>
                {data.length}
              </Badge>
            )}
            <span>Frozen Invoices</span>
          </div>
          <div className="w-48  h-[36px] flex items-center">
            <Input
              className="!py-0.5 !h-8"
              placeholder="Type to search..."
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </div>
        <InvTable data={dataFiltered} dragData={{ from: 'frozen' }} />
      </Card>
    </Droppable>
  )
}

const InvTableReduced = (props) => {
  const columns = useMemo(() => [
    {
      header: '',
      accessorKey: 'lines',
      cellSize: 1,
      disableSortBy: true,
      noPadAnywhere: true,
      cell: ({ row }) => (
        <div className="flex items-center h-full" data-no-dnd="true">
          <Button
            variant="rounded"
            tiny
            onClick={() =>
              NiceModal.show('assignmentViewLinesModal', { docNo: row.original.Document })
            }
            Icon={ListBulletIcon}
          />
        </div>
      )
    },
    {
      header: '',
      accessorKey: 'warn',
      cellSize: 1,
      noPadAnywhere: true,
      cell: ({ row }) =>
        row.original.Loc && row.original.Loc[0] !== null ? null : (
          <div className="flex items-center" title="Customer does not have a loc">
            <ExclamationCircleIcon className="mr-1 w-5 h-5 text-red-500" />
          </div>
        )
    },
    {
      header: 'ACCT',
      accessorKey: 'Name',
      cell: ({ row }) => (
        <span>
          <span className="font-bold">[{row.original.Acct}]</span> {row.original.Name}
        </span>
      )
    },
    {
      header: 'DOC',
      accessorKey: 'Document'
    },
    {
      header: 'LINES',
      accessorKey: 'LinesQty'
    },
    {
      header: 'ITEMS',
      accessorKey: 'ItemsQty'
    },
    {
      header: 'VALUE',
      accessorKey: 'Goods',
      cell: ({ row }) =>
        row.original.Goods ? `£${parseFloat(row.original.Goods).toFixed(2)}` : '£0.00'
    }
  ])

  const data = useMemo(() => props.data, [props.data])

  return (
    <DraggableTable
      cols={columns}
      data={data}
      isCompact={true}
      thinRows={true}
      notFull={true}
      isPaginated={false}
      dragData={(row) => ({ ...props.dragData, original: row })}
    />
  )
}

const InvTable = (props) => {
  const columns = useMemo(() => [
    {
      header: '',
      accessorKey: 'lines',
      cellSize: 1,
      disableSortBy: true,
      noPadAnywhere: true,
      cell: ({ row }) => (
        <div className="flex items-center h-full" data-no-dnd="true">
          <Button
            variant="rounded"
            tiny
            onClick={() =>
              NiceModal.show('assignmentViewLinesModal', { docNo: row.original.Document })
            }
            Icon={ListBulletIcon}
          />
        </div>
      )
    },
    {
      header: '',
      accessorKey: 'warn',
      cellSize: 1,
      noPadAnywhere: true,
      cell: ({ row }) =>
        row.original.Loc ? null : (
          <div className="flex items-center" title="Customer does not have a loc">
            <ExclamationCircleIcon className="mr-1 w-5 h-5 text-red-500" />
          </div>
        )
    },
    {
      header: 'TIME',
      id: 'time',
      cell: ({ row }) => moment.tz(row.original.DateTime, 'Etc/UTC').format('HH:mm DD/MM/YY')
    },
    {
      header: 'ACCT',
      accessorKey: 'Name',
      cell: ({ row }) => (
        <span>
          <span className="font-bold">[{row.original.Acct}]</span> {row.original.Name}
        </span>
      )
    },
    {
      header: 'DOC',
      accessorKey: 'Document'
    },
    {
      header: 'LINES',
      accessorKey: 'LinesCount'
    },
    {
      header: 'ITEMS',
      accessorKey: 'ItemsQty'
    },
    // {
    //   header: 'ClsFlag',
    //   accessorKey: 'ClsFlag'
    // },
    {
      header: 'VALUE',
      accessorKey: 'Goods',
      cell: ({ row }) =>
        row.original.Goods ? `£${parseFloat(row.original.Goods).toFixed(2)}` : '£0.00'
    }
  ])

  const data = useMemo(() => props.data, [props.data])

  return (
    <DraggableTable
      cols={columns}
      data={data}
      isCompact={true}
      thinRows={true}
      notFull={true}
      isPaginated={true}
      dragData={props.dragData}
      totalRowsCount={data.length}
    />
  )
}

export default Assignment
