import NiceModal from '@ebay/nice-modal-react'
import { CameraIcon, ListBulletIcon, PencilSquareIcon } from '@heroicons/react/24/outline'
import { TruckIcon, FlagIcon } from '@heroicons/react/24/solid'
import { Badge, Button } from 'components/gsys-ui'
import moment from 'moment'
import { useDropImage, useDropImageFromDropId, useRewindRun } from 'util/queries'

const Timeline = ({ runId }) => {
  const { isLoading, isError, error, data } = useRewindRun(runId)

  console.log('rewinddata', data)

  if (isLoading) return null

  // Set explicit Status for each drop (Status is missing from regular drops)
  const dropsWithStatus = data.Drops.map((drop) => ({
    ...drop,
    Status: 'Complete' // Since these are completed runs
  }))

  const dataWithStart = [
    {
      ...dropsWithStatus[dropsWithStatus.length - 1],
      Status: 'Complete',
      Name: data.Vehicle.Branch.toUpperCase(),
      Date: dropsWithStatus[0].Date,
      CompletedTime: dropsWithStatus[0].StartDate
    },
    ...dropsWithStatus
  ]

  return (
    <div>
      {dataWithStart.map((drop, ind) => (
        <Step
          drop={drop}
          first={ind === 0}
          last={ind === dataWithStart.length - 1}
          podId={drop.PodId}
          runId={runId}
          key={JSON.stringify(drop)}
        />
      ))}
    </div>
  )
}

export const Step = ({ drop, active, first, last, runId }) => {
  // Function to determine the correct timestamp to display
  console.info(JSON.stringify(drop))

  const getTimeDisplay = () => {
    if (drop.Status === 'Waiting') return ''

    // For completed drops, prioritize CompletedTime
    if (drop.CompletedTime) {
      return moment(drop.CompletedTime).format('DD/MM/YY HH:mm')
    }

    if (drop.Eta) {
      return <span className="text-gray-400">{moment(drop.Eta).format('DD/MM/YY HH:mm')}</span>
    }

    // Otherwise fallback to other date fields
    else if (drop.Date) {
      return moment(drop.Date).format('DD/MM/YY HH:mm')
    } else if (drop.CompletedDate) {
      return moment(drop.CompletedDate).format('DD/MM/YY HH:mm')
    }
    // Final fallback
    return 'No time data'
  }

  return (
    <div className="flex min-h-[70px]">
      <div className="pr-4 leading-tight text-right text-neutral-800 w-[145px]">
        {getTimeDisplay()}
      </div>
      <div className="relative w-[12px]">
        {!last &&
          (['Pending', 'Waiting'].includes(drop.Status) ? (
            <div className="absolute h-full w-[1px] top-[6px] border-l border-dashed border-neutral-400  left-[6px]" />
          ) : (
            <div className="absolute h-full w-[1px] top-[6px] border-l border-blue-500 left-[6px]" />
          ))}
        {/*
          last && (
            <div className="absolute h-[70%] w-[1px] bg-gradient-to-t from-blue-500 to-transparent left-[6px] bottom-[73px]" />
          )
          */}
        {active ? (
          <div className="absolute top-[-3px] left-[-6px] w-[25px] h-[25px] rounded-full bg-blue-500 flex items-center justify-center">
            <TruckIcon className="w-[19px] h-[19px] text-white mb-[2px] ml-[1px]" />
          </div>
        ) : ['Pending', 'Waiting'].includes(drop.Status) ? (
          <div className="absolute top-[3px] w-[13px] h-[13px] rounded-full outline outline-1 outline-neutral-400 bg-white" />
        ) : last ? (
          <div className="absolute top-[-3px] left-[-6px] w-[25px] h-[25px] rounded-full bg-blue-500 flex items-center justify-center">
            <FlagIcon className="w-[17px] h-[17px] text-white" />
          </div>
        ) : (
          <div className="absolute top-[3px] w-[13px] h-[13px] rounded-full bg-blue-500" />
        )}
      </div>
      <div className="flex-1 pl-4">
        <div className="font-bold leading-tight">
          {!drop.Acct || drop.Acct === '|none|' ? drop.Name : `[${drop.Acct}] ${drop.Name}`}
        </div>
        {drop.Notes && (
          <div>
            <span className="font-bold">Notes: </span>
            <span className="italic">{drop.Notes}</span>
          </div>
        )}
        <div className="flex items-center pb-3">
          {!first &&
            !last &&
            (active ? (
              <Badge variant="orange" className="w-[78px] mr-2">
                In Progress
              </Badge>
            ) : drop.Status === 'Pending' ? (
              <Badge variant="blue" className="w-[78px] mr-2">
                Pending
              </Badge>
            ) : drop.Status === 'Waiting' ? (
              <Badge variant="blue" className="w-[78px] mr-2">
                Waiting
              </Badge>
            ) : (
              <Badge variant="green" className="w-[78px] mr-2">
                Complete
              </Badge>
            ))}
          {drop.Acct && drop.Acct !== '|none|' && (
            <Button
              variant="borderless"
              rounded
              small
              onClick={() =>
                NiceModal.show('rewindViewLinesModal', { runId, dropId: drop.DropId || drop._id })
              }
            >
              <ListBulletIcon className="w-5 h-5" />
            </Button>
          )}
          {drop.PodId && (
            <Button
              variant="borderless"
              rounded
              small
              onClick={() => NiceModal.show('podsViewSignatureModal', { podId: drop.PodId })}
            >
              <PencilSquareIcon className="w-5 h-5" />
            </Button>
          )}
          {drop.isPod === true && (
            <Button
              variant="borderless"
              rounded
              small
              onClick={() => NiceModal.show('podsViewSignatureModal', { dropId: drop.DropId })}
            >
              <PencilSquareIcon className="w-5 h-5" />
            </Button>
          )}
          {drop.DropImageId && (
            <Button
              variant="borderless"
              rounded
              small
              onClick={() =>
                NiceModal.show('lightboxModal', {
                  useQuery: () => useDropImage(drop.DropImageId)
                })
              }
            >
              <CameraIcon className="w-5 h-5" />
            </Button>
          )}
          {drop.isPhoto === true && (
            <Button
              variant="borderless"
              rounded
              small
              onClick={() =>
                NiceModal.show('lightboxModal', {
                  useQuery: () => useDropImageFromDropId(drop.DropId)
                })
              }
            >
              <CameraIcon className="w-5 h-5" />
            </Button>
          )}
        </div>
      </div>
    </div>
  )
}

export default Timeline
