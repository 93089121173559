import NiceModal, { useModal } from '@ebay/nice-modal-react'

import ModalContainer from 'components/ModalContainer'
import { useCreateRun } from 'util/mutations'
import { useState } from 'react'
import { BellIcon, CheckIcon, TruckIcon, UserIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { Button, Card, Input } from 'components/gsys-ui'
import { sendExpoPushNotification } from 'util/helpers'
import TextArea from 'antd/es/input/TextArea'
import haxios from 'util/haxios'

const SendDriverNotificationModal = NiceModal.create(({ driverId }) => {
  const modal = useModal()
  const [isSubmitting, setSubmitting] = useState(false)
  const [errorText, setErrorText] = useState(null)
  const [inputValue, setInputValue] = useState('')

  const handleSubmit = async (res) => {
    setSubmitting(true)

    try {
      await haxios.post(`/dd/driver/notify`, { token: driverId, message: inputValue })
    } catch (e) {
      console.log(e)
    }

    modal.remove()
  }

  return (
    <ModalContainer>
      <Card className="!w-[350px]">
        <Card.Title Icon={BellIcon}>Send a notification</Card.Title>
        <Card.Body>
          <div>!!{JSON.stringify(driverId)}</div>
          <div className="space-y-2">
            {errorText && <div className="text-red-500">{JSON.stringify(errorText)}</div>}
            <div>
              <TextArea
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                placeholder="Notification content"
              />
            </div>
            <div className="flex justify-end space-x-2">
              <Button Icon={XMarkIcon} variant="white" onClick={modal.remove}>
                Cancel
              </Button>
              <Button
                Icon={CheckIcon}
                onClick={handleSubmit}
                variant="green"
                loading={isSubmitting}
              >
                Send
              </Button>
            </div>
          </div>
        </Card.Body>
      </Card>
    </ModalContainer>
  )
})

export default SendDriverNotificationModal
