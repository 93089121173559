import RegPlate from 'components/RegPlate'
import { Badge, Button, Card, Col, Grid, Spinner } from 'components/gsys-ui'
import { useActiveDrivers, useCompletedRun } from 'util/queries'
import moment from 'moment'
import EmptyMsg from 'components/EmptyMsg'
import Map from 'components/Map'
import DriverCard from 'components/active/DriverCard'
import { ArchiveBoxIcon, ArrowUturnLeftIcon, BellIcon } from '@heroicons/react/24/outline'
import { Step } from 'components/rewind/Timeline'
import MechanicIcon from 'components/MechanicIcon'
import { apir } from 'util/haxios'
import { Link, useParams } from '@tanstack/react-router'
import VanIcon from 'components/VanIcon'
import NiceModal from '@ebay/nice-modal-react'

const Active = () => {
  const { isLoading, isError, error, data } = useActiveDrivers()
  const { runId } = useParams()
  let selectedRun = data ? data.filter((el) => el.ActiveRuns[0].RunId === runId).pop() : null
  const {
    isLoading: compIsLoading,
    isError: compIsError,
    error: compError,
    data: compData
  } = useCompletedRun(runId, { enabled: !!!selectedRun })

  if (isLoading || compIsLoading) return <Spinner size={30} cont={true} />
  if (!data || data.length === 0) return <EmptyMsg>No data to display.</EmptyMsg>
  if (!selectedRun) selectedRun = compData
  if (!selectedRun) return null

  const points = [
    selectedRun?.Location?.[0],
    ...selectedRun.ActiveRuns.map((el) => ({ lat: el.Loc[0].lat, lon: el.Loc[0].lon }))
  ].filter(Boolean)

  const dataWithStart = [
    {
      ...selectedRun.ActiveRuns[selectedRun.ActiveRuns - 1],
      Status: 'Complete',
      Name: data[0].Vehicle.Branch.toUpperCase(),
      Date: selectedRun.ActiveRuns[0].Date
    },
    ...selectedRun.ActiveRuns
  ]

  const firstPendingDrop = selectedRun.ActiveRuns.findIndex((el, ind) => {
    if (ind === 0 && el.Status === 'Pending') return true
    if (el.Status == 'Pending' && selectedRun.ActiveRuns[ind - 1].Status === 'Complete') return true
    return false
  })

  const firstPendingIsLast = firstPendingDrop === selectedRun.ActiveRuns.length - 1

  console.log(selectedRun)

  return (
    <Grid gap={7} className="p-2 h-full">
      <Col sm={8}>
        <div className="space-y-2">
          <div className="flex justify-between">
            <Link to="/active">
              <Button>
                <ArrowUturnLeftIcon className="mr-1 w-5 h-5" />
                Back
              </Button>
            </Link>
            <div className="flex items-center space-x-2">
              {/* <Button>
                <ArchiveBoxIcon className="mr-1 w-5 h-5" />
                Add collection
              </Button> */}
              <Button
                onClick={() =>
                  NiceModal.show('activeSendDriverNotificationModal', { driverId: data[0].Driver })
                }
              >
                <BellIcon className="mr-1 w-5 h-5" />
                Notify driver
              </Button>
            </div>
          </div>
          <Card className="px-3 pt-2">
            <div className="flex justify-between items-center mb-5 text-lg font-bold">
              <div className="flex items-center">
                <div className="mr-2">
                  <RegPlate size={0.8}>{selectedRun.Vehicle.Reg}</RegPlate>
                </div>
                <div>{selectedRun.Vehicle.Branch}</div>
              </div>
              <div className="flex space-x-4">
                {selectedRun.ActiveRuns[0].Status === 'Waiting' ? (
                  <Badge variant="orange">Waiting</Badge>
                ) : (
                  <>
                    <div className="flex flex-col items-center">
                      <div className="text-2xl font-bold leading-none">
                        {moment(selectedRun.ActiveRuns[0].Date).format('HH:mm')}
                      </div>
                      <div className="text-sm select-none">START</div>
                    </div>
                    <div className="flex flex-col items-center">
                      <div className="text-2xl font-bold leading-none">
                        {moment(selectedRun.ActiveRuns.slice(-1)[0].Eta).format('HH:mm')}
                      </div>
                      <div className="text-sm select-none">EST. RETURN</div>
                    </div>
                  </>
                )}
              </div>
            </div>
            {dataWithStart.map((drop, ind) => (
              <Step
                drop={drop}
                first={ind === 0}
                last={ind === dataWithStart.length - 1}
                runId={runId}
                active={
                  dataWithStart.findIndex((el, ind) => {
                    const isPending = el.Status === 'Pending'
                    const next = dataWithStart[ind - 1]
                    const nextIsComplete = next === undefined || next.Status === 'Complete'
                    return isPending && nextIsComplete
                  }) === ind
                }
              />
            ))}
          </Card>
        </div>
      </Col>
      <Col sm={16}>
        <Map points={points} noCenterPointsChange>
          {selectedRun.Location && (
            <VanIcon
              lat={selectedRun.Location[0].lat}
              lng={selectedRun.Location[0].lon}
              reg={selectedRun.Vehicle.Reg}
              returnTime={
                firstPendingIsLast
                  ? selectedRun.ActiveRuns[selectedRun.ActiveRuns.length - 1].Eta
                  : null
              }
            />
          )}
          {selectedRun.ActiveRuns.map((el, ind) => {
            if (el.Acct === '|none|') {
              return (
                <div
                  className="flex flex-col items-center absolute translate-x-[-50%] translate-y-[-100%]"
                  key={ind}
                  lat={el.Loc[0].lat}
                  lng={el.Loc[0].lon}
                >
                  <Card>
                    <img className="w-12" src={`${apir}/public/images/logo.jpg`} />
                  </Card>
                </div>
              )
            }

            return (
              <MechanicIcon key={ind} lat={el.Loc[0].lat} lng={el.Loc[0].lon} name={`${el.Name}`} />
            )
          })}
        </Map>
      </Col>
    </Grid>
  )
}

export default Active
