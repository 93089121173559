import { createContext, useEffect, useState } from 'react'

export const GlobalContext = createContext()

export const GlobalProvider = ({ children }) => {
  const [cinfo, setCinfo] = useState(null)
  const [uinfo, setUinfo] = useState(null)
  const [currencySymbol, setCurrencySymbol] = useState(null)
  const [brandingBlob, setBrandingBlob] = useState(null)
  const [branch, setBranch] = useState(JSON.parse(localStorage.getItem('branch')))
  const [loginError, setLoginError] = useState(null)

  useEffect(() => {
    if (!cinfo) return
    cinfo.CurrencyCode == 'GBP' ? setCurrencySymbol('£') : setCurrencySymbol('€')
  }, [cinfo])

  const handleSetBranch = (newBranch) => {
    console.info('newBranch', newBranch)
    localStorage.setItem('branch', JSON.stringify(newBranch.value))
    localStorage.setItem('pcode', JSON.stringify(newBranch.pcode))
    localStorage.setItem('location', JSON.stringify(newBranch.location))
    setBranch(newBranch.value)
  }

  const value = {
    cinfo, setCinfo,
    uinfo, setUinfo,
    currencySymbol,
    brandingBlob, setBrandingBlob,
    branch, setBranch: handleSetBranch,
    loginError, setLoginError
  }

  return (
    <GlobalContext.Provider value={value}>
      {children}
    </GlobalContext.Provider>
  )
}