import NiceModal, { useModal } from '@ebay/nice-modal-react'

import ModalContainer from 'components/ModalContainer'
import { useAddCustomerToRun } from 'util/mutations'
import { useState } from 'react'
import { CheckIcon, UserIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { Button, Card } from 'components/gsys-ui'
import CustomerDropdown from './CustomerDropdown'
import CustomerDropdownLoc from './CustomerDropdownLoc'


const AddCustomerModal = NiceModal.create(({ runId }) => {
  const modal = useModal()
  const [isSubmitting, setSubmitting] = useState(false)
  const [errorText, setErrorText] = useState(null)
  const [selectedCustomer, setSelectedCustomer] = useState(null)
  const mutation = useAddCustomerToRun()

  const handleSubmit = async (res) => {
    setSubmitting(true)

    try {
      await mutation.mutateAsync({ runId, acct: selectedCustomer.value })
    } catch (e) {

    }

    modal.remove()
  }

  return (
    <ModalContainer>
      <Card className="!w-[350px]">
        <Card.Title Icon={UserIcon}>Add customer to run</Card.Title>
        <Card.Body>
          <div className="space-y-2">
            {
              errorText && (
                <div className="text-red-500">{JSON.stringify(errorText)}</div>
              )
            }
            <div>
              <CustomerDropdownLoc
                selectedCustomer={selectedCustomer}
                setSelectedCustomer={setSelectedCustomer}
              />
            </div>
            <div className="flex justify-end space-x-2">
              <Button Icon={XMarkIcon} variant="white" onClick={modal.remove}>Cancel</Button>
              <Button Icon={CheckIcon} onClick={handleSubmit} variant="green" loading={isSubmitting}>Submit</Button>
            </div>
          </div>
        </Card.Body>
      </Card>
    </ModalContainer>
  )
})

export default AddCustomerModal