import NiceModal, { useModal } from '@ebay/nice-modal-react'
import {
  CameraIcon,
  DocumentTextIcon,
  ListBulletIcon,
  PencilSquareIcon,
  XMarkIcon
} from '@heroicons/react/24/outline'
import { apir } from 'util/haxios'

import ModalContainer from 'components/ModalContainer'
import { Button, Card, Form, MultiButton, Table } from 'components/gsys-ui'
import { useRewindDropLines } from 'util/queries'
import { useState } from 'react'

const ViewLinesModal = NiceModal.create(({ runId, dropId }) => {
  const modal = useModal()
  const [multi, setMulti] = useState('invoices')
  const { isLoading, isError, error, data } = useRewindDropLines({ runId, dropId })

  if (isLoading) return null

  let multiOpt = [{ label: 'Invoices', value: 'invoices' }]
  if (data?.Returns && data.Returns.length) multiOpt.push({ label: 'Returns', value: 'returns' })
  if (data?.Cash && data.Cash.length) multiOpt.push({ label: 'Cash', value: 'cash' })

  return (
    <ModalContainer>
      <Card className="!w-[600px]">
        <Card.Title Icon={ListBulletIcon}>
          Drop details
          <MultiButton options={multiOpt} value={multi} onChange={setMulti} />
        </Card.Title>
        <Card.Body>
          {multi === 'invoices' ? (
            <InvoicesTable data={data.Documents} />
          ) : multi === 'returns' ? (
            <ReturnsTable data={data.Returns} />
          ) : multi === 'cash' ? (
            <CashTable data={data.Cash} />
          ) : null}
          <div className="flex justify-end mt-2">
            <Button Icon={XMarkIcon} variant="white" onClick={modal.remove}>
              Close
            </Button>
          </div>
        </Card.Body>
      </Card>
    </ModalContainer>
  )
})

const InvoicesTable = ({ data }) => {
  return (
    <>
      {data &&
        data.map((doc, ind) => (
          <div>
            <div className="flex items-center mt-2 font-bold">
              <DocumentTextIcon className="mr-1 w-6 h-6" />
              <div>Invoice {doc.Document}</div>
            </div>
            <Table
              key={ind}
              data={doc.Lines}
              columns={[
                {
                  header: 'PART',
                  accessorKey: 'Part'
                },
                {
                  header: 'QTY',
                  accessorKey: 'Qty'
                },
                {
                  header: 'DESCRIPTION',
                  accessorKey: 'BopDes'
                }
              ]}
            />
          </div>
        ))}
    </>
  )
}

const ReturnsTable = ({ data }) => {
  return (
    <Table
      data={data[0].Parts}
      columns={[
        {
          header: 'PART',
          accessorKey: 'PartNo'
        },
        {
          header: 'QTY',
          accessorKey: 'Qty'
        },
        {
          header: 'REASON',
          accessorKey: 'Reason'
        }
      ]}
    />
  )
}

const CashTable = ({ data }) => {
  return (
    <Table
      data={data}
      columns={[
        {
          header: 'Amount',
          accessorKey: 'amount',
          cell: ({ row }) => `£${row.original.Amount.toFixed(2)}`
        },
        {
          header: 'NOTES',
          accessorKey: 'Notes'
        }
      ]}
    />
  )
}

export default ViewLinesModal
