import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { useState } from 'react'
import { CheckIcon, ClockIcon, UserIcon, XMarkIcon } from '@heroicons/react/24/outline'
import moment from 'moment-timezone'
import dayjs from 'dayjs'
import TimePicker from 'rc-time-picker'
import 'rc-time-picker/assets/index.css'

import ModalContainer from 'components/ModalContainer'
import { Button, Card, Input } from 'components/gsys-ui'
import { useAddTimeToRun, useCreateTrip } from 'util/mutations'

moment.tz.setDefault('Europe/London')

const AddRunModal = NiceModal.create(({ branch }) => {
  const modal = useModal()
  const [isSubmitting, setSubmitting] = useState(false)
  const [errorText, setErrorText] = useState(null)
  const [time, setTime] = useState(moment().startOf('day'))
  const [inputValue, setInputValue] = useState('')
  const mutation = useCreateTrip()

  const handleSubmit = async (res) => {
    setSubmitting(true)
    console.log(time)

    try {
      await mutation.mutateAsync({ branch, name: inputValue, time: time.toDate() })
    } catch (e) {}

    modal.remove()
  }

  return (
    <ModalContainer>
      <Card className="!w-[350px]">
        <Card.Title Icon={ClockIcon}>Create a new run</Card.Title>
        <Card.Body>
          <div className="space-y-2">
            {errorText && <div className="text-red-500">{JSON.stringify(errorText)}</div>}
            <div className="pt-2">
              <Input
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                placeholder="Run name"
              />
            </div>
            <div className="py-2">
              <TimePicker
                defaultValue={time}
                onChange={setTime}
                showSecond={false}
                minuteStep={5}
                allowEmpty={false}
                inputClassName="text-md"
              />
            </div>
            <div className="flex justify-end space-x-2">
              <Button Icon={XMarkIcon} variant="white" onClick={modal.remove}>
                Cancel
              </Button>
              <Button
                Icon={CheckIcon}
                onClick={handleSubmit}
                variant="green"
                loading={isSubmitting}
              >
                Submit
              </Button>
            </div>
          </div>
        </Card.Body>
      </Card>
    </ModalContainer>
  )
})

export default AddRunModal
