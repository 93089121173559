import NiceModal, { useModal } from '@ebay/nice-modal-react'
import {
    ArrowPathIcon,
    ChevronDownIcon,
    ChevronUpIcon,
    MapPinIcon,
    TruckIcon,
    XMarkIcon
} from '@heroicons/react/24/outline'
import { Badge, Button, Card } from 'components/gsys-ui'
import moment from 'moment-timezone'
import { useContext, useEffect, useRef, useState } from 'react'
import mapboxgl from 'mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css'
import { DndContext, closestCenter, useSensor, useSensors } from '@dnd-kit/core'
import { SortableContext, verticalListSortingStrategy, useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { restrictToVerticalAxis } from '@dnd-kit/modifiers'
import { MouseSensor, TouchSensor } from '@dnd-kit/core'
import { apir } from 'util/haxios'
import CustomerDropdown from 'components/runs/CustomerDropdown'
import { useInvoice } from 'util/queries'
import { GlobalContext } from 'context/GlobalContext'
import { v4 as uuid } from 'uuid';


// Replace with your actual Mapbox access token
// This should ideally come from an environment variable
const MAPBOX_ACCESS_TOKEN = 'pk.eyJ1IjoiYXV0b21tIiwiYSI6ImNsbmZ1d2c4NDA5eWQycW1pcjEyNnNndWwifQ.UgUcly5Rs-IE9NFCU0gt6g'
mapboxgl.accessToken = MAPBOX_ACCESS_TOKEN

// CollectionModal component for adding collections
const CollectionModal = NiceModal.create(({ tripId, onAddCollection }) => {
    const modal = useModal()
    const [selectedCustomer, setSelectedCustomer] = useState(null)
    const [reason, setReason] = useState('')
    const [notes, setNotes] = useState('')

    const handleAddCollection = () => {
        // Only proceed if a customer has been selected
        if (!selectedCustomer) return;

        // Call the passed callback with collection details
        onAddCollection({
            customerId: selectedCustomer.value,
            customerName: selectedCustomer.label.split('] ')[1], // Extract the name from the label
            customerAcct: selectedCustomer.value, // Using KeyCode as the account number
            reason,
            notes,
            isCollection: true
        })
        modal.remove()
    }

    return (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
            <Card className="w-1/2 max-w-2xl flex flex-col">
                <div className="flex justify-between items-center px-4 py-3 border-b border-gray-200">
                    <h2 className="text-xl font-bold">Add Collection</h2>
                    <Button
                        variant="rounded"
                        onClick={() => modal.remove()}
                        Icon={XMarkIcon}
                    />
                </div>

                <div className="p-4 space-y-4">
                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">Customer</label>
                        <CustomerDropdown
                            selectedCustomer={selectedCustomer}
                            setSelectedCustomer={setSelectedCustomer}
                        />
                    </div>

                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">Reason for Collection</label>
                        <select
                            className="w-full rounded-md border border-gray-300 p-2"
                            value={reason}
                            onChange={(e) => setReason(e.target.value)}
                        >
                            <option value="">Select a reason</option>
                            <option value="return">Return</option>
                            <option value="exchange">Exchange</option>
                            <option value="recall">Product Recall</option>
                            <option value="repair">Repair</option>
                            <option value="other">Other</option>
                        </select>
                    </div>

                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">Notes</label>
                        <textarea
                            className="w-full rounded-md border border-gray-300 p-2 h-24"
                            value={notes}
                            onChange={(e) => setNotes(e.target.value)}
                            placeholder="Add details about the collection..."
                        />
                    </div>
                </div>

                <div className="flex justify-end space-x-2 px-4 py-3 border-t border-gray-200">
                    <Button
                        variant="secondary"
                        onClick={() => modal.remove()}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="primary"
                        onClick={handleAddCollection}
                        disabled={!selectedCustomer || !reason}
                    >
                        Add Collection
                    </Button>
                </div>
            </Card>
        </div>
    )
})


// SortableDropItem component with hover functionality
const SortableDropItem = ({ drop, index, onExpandToggle, expanded, onHoverStart, onHoverEnd, invoiceDetails, fetchInvoiceDetails }) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
        isDragging
    } = useSortable({ id: drop.id })

    useEffect(() => {
        if (expanded && !drop.isCollection) {
            // Fetch details for each document in this drop
            drop.documents.forEach(doc => {
                // Only fetch if we don't already have it
                if (!invoiceDetails[doc.id]) {
                    fetchInvoiceDetails(doc.id);
                }
            });
        }
    }, [expanded, drop.id, drop.documents, invoiceDetails]);


    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        zIndex: isDragging ? 100 : 1,
        position: 'relative',
        opacity: isDragging ? 0.8 : 1,
    }

    return (
        <div
            ref={setNodeRef}
            style={style}
            className="bg-white border border-gray-200 rounded-md mb-2 overflow-hidden"
            onMouseEnter={() => onHoverStart(drop.id)}
            onMouseLeave={() => onHoverEnd()}
        >
            <div className="flex items-center justify-between p-3 bg-gray-50 hover:bg-gray-100">
                {/* Drag handle area */}
                <div
                    className="flex items-center space-x-2 flex-1 cursor-grab"
                    {...attributes}
                    {...listeners}
                >
                    <span className="inline-flex items-center justify-center bg-gray-200 rounded-full w-6 h-6 text-sm">
                        {index + 1}
                    </span>
                    <span className="font-medium">
                        {drop.isCollection ? '📥 COLLECTION: ' : ''}
                        [{drop.acct}] {drop.name}
                    </span>
                    {!drop.location && (
                        <span className="text-xs text-red-500" title="Missing location data">
                            ⚠️ No Location
                        </span>
                    )}
                </div>

                <div className="flex items-center space-x-3">
                    <span className="text-sm bg-blue-100 text-blue-800 px-2 py-0.5 rounded">
                        {drop.itemCount} {drop.itemCount === 1 ? 'item' : 'items'}
                    </span>

                    {/* Toggle button */}
                    <button
                        type="button"
                        onClick={() => onExpandToggle(drop.id)}
                        className="text-gray-500 hover:text-gray-700 cursor-pointer"
                    >
                        {expanded ?
                            <ChevronUpIcon className="w-5 h-5" /> :
                            <ChevronDownIcon className="w-5 h-5" />
                        }
                    </button>
                </div>
            </div>

            {expanded && (
                <div className="p-3 border-t border-gray-200 bg-white">
                    {drop.isCollection ? (
                        // Collection details
                        <div className="space-y-3">
                            <h4 className="text-sm font-medium text-gray-500 mb-2">Collection Details:</h4>
                            <div className="p-2 bg-gray-50 rounded-md">
                                <p className="text-sm text-gray-700">
                                    <span className="font-medium">Collection Type:</span> {drop.collectionType || "Standard"}
                                </p>
                                {drop.notes && (
                                    <p className="text-sm text-gray-700 mt-2">
                                        <span className="font-medium">Notes:</span> {drop.notes}
                                    </p>
                                )}
                            </div>
                        </div>
                    ) : (
                        // Drop details (documents)
                        <>
                            <h4 className="text-sm font-medium text-gray-500 mb-2">Documents:</h4>
                            <div className="space-y-3">
                                {drop.documents && drop.documents.map((doc) => (
                                    <div key={doc.id} className="border-b border-gray-100 pb-2 last:border-b-0 last:pb-0">
                                        <div className="flex justify-between items-center mb-1">
                                            <span className="font-medium">{doc.id}</span>
                                            <span className="text-sm text-gray-500">
                                                {invoiceDetails[doc.id]?.length || 0} {invoiceDetails[doc.id]?.length === 1 ? 'item' : 'items'}
                                            </span>
                                        </div>
                                        <ul className="text-sm pl-4 space-y-1">
                                            {invoiceDetails[doc.id] ? (
                                                // Display real line items
                                                invoiceDetails[doc.id].map((item, i) => (
                                                    <li key={i} className="flex justify-between">
                                                        <span className="text-gray-700">
                                                            {item.Part}: {item.BopDes}
                                                        </span>
                                                        <span className="text-gray-900 font-medium">x{item.Qty}</span>
                                                    </li>
                                                ))
                                            ) : (
                                                <li className="text-gray-500">Loading items...</li>
                                            )}
                                        </ul>
                                    </div>
                                ))}
                            </div>
                        </>
                    )}
                </div>
            )}
        </div>
    )
}

// Main TripModal component with all hover functionality implemented
export const TripModal = NiceModal.create(({ tripData }) => {
    const modal = useModal()
    const mapContainer = useRef(null)
    const map = useRef(null)
    const [expandedDrops, setExpandedDrops] = useState([])
    const [drops, setDrops] = useState([])
    const [vehicles, setVehicles] = useState([])
    const [loadingVehicles, setLoadingVehicles] = useState(false)
    const [selectedVehicle, setSelectedVehicle] = useState('')
    const [estimatedFinishTime, setEstimatedFinishTime] = useState('')
    const [route, setRoute] = useState(null)
    const [loading, setLoading] = useState({
        map: true,
        route: false,
        autoRoute: false
    })
    // Add state for tracking hovered drop
    const [hoveredDropId, setHoveredDropId] = useState(null)
    const [invoiceDetails, setInvoiceDetails] = useState({})
    const { branch } = useContext(GlobalContext)

    const [assignLoading, setAssignLoading] = useState(false);

    useEffect(() => {
        const fetchVehicles = async () => {
            try {
                setLoadingVehicles(true)

                const response = await fetch(`${apir}/dd/vehiclesNotActive?branch=${encodeURIComponent(branch)}`)

                if (!response.ok) {
                    throw new Error(`Error fetching vehicles: ${response.status}`)
                }

                const data = await response.json()

                // Transform the API data into the format we need
                const formattedVehicles = data.map(vehicle => ({
                    id: vehicle._id,
                    reg: vehicle.Reg,
                    name: `${vehicle.Reg} - ${vehicle.Make} ${vehicle.Model}`,
                    make: vehicle.Make,
                    model: vehicle.Model,
                    color: vehicle.Colour,
                    branch: vehicle.Branch
                }))

                setVehicles(formattedVehicles)
            } catch (error) {
                console.error('Error fetching vehicles:', error)
            } finally {
                setLoadingVehicles(false)
            }
        }

        fetchVehicles()
    }, [])


    // Use ref to store references to all markers
    const markerRefs = useRef({})
    console.info('TripModal', tripData)
    const recalculateEstimatedFinishTime = (drops, departureTime) => {
        // Simple estimation: 20 mins per drop + 30 mins back to branch
        const estimatedDuration = (drops.length * 20) + 30;
        const finishTime = moment(departureTime).clone().add(estimatedDuration, 'minutes');
        return finishTime.format('HH:mm');
    };

    // Add this function inside TripModal but outside any other function
    const fetchInvoiceDetails = (docId) => {
        // Use promise-based API call instead of hook
        fetch(`${apir}/dd/invoices/${docId}`)
            .then(response => response.json())
            .then(data => {
                setInvoiceDetails(prev => ({
                    ...prev,
                    [docId]: data
                }));
            })
            .catch(error => {
                console.error(`Error fetching details for invoice ${docId}:`, error);
            });
    };

    // Get branch location from localStorage with fallback to a default
    const getBranchLocation = () => {
        // Read from the localStorage keys your app is actually using
        const branchName = localStorage.getItem('branch');
        const locationJson = localStorage.getItem('location');

        console.log('Reading from localStorage:', { branchName, locationJson });

        let lat = 51.68291; // Default Waltham
        let lng = -0.00358; // Default Waltham
        let postcode = 'WALTHAM'; // Default

        // Try to parse the location JSON
        try {
            if (locationJson) {
                const locationData = JSON.parse(locationJson);
                console.log('Parsed location data:', locationData);

                // Check if it's a GeoJSON Point
                if (locationData.type === 'Point' &&
                    Array.isArray(locationData.coordinates) &&
                    locationData.coordinates.length === 2) {

                    // GeoJSON uses [longitude, latitude] order
                    lng = locationData.coordinates[0];
                    lat = locationData.coordinates[1];

                    // Use the branch name as postcode if available
                    if (branchName) {
                        postcode = branchName;
                    }
                }
            }
        } catch (error) {
            console.error('Error parsing location from localStorage:', error);
        }

        return { lat, lng, postcode };
    };
    const [branchLocation, setBranchLocation] = useState(getBranchLocation());

    // Add hover functionality CSS
    useEffect(() => {
        // Create a style element for the marker highlight effect
        const style = document.createElement('style');
        style.textContent = `
            .highlighted-marker {
                transform: scale(1.3);
                z-index: 10 !important;
                transition: transform 0.2s ease-in-out;
            }
            .highlighted-marker > div {
                background-color: #ff3b3b !important;
                box-shadow: 0 0 0 3px rgba(255, 59, 59, 0.5);
            }
        `;
        document.head.appendChild(style);

        // Clean up the style element when the component unmounts
        return () => {
            if (document.head.contains(style)) {
                document.head.removeChild(style);
            }
        };
    }, []);

    // Handle hover start - highlight the marker on the map
    const handleHoverStart = (dropId) => {
        setHoveredDropId(dropId);

        // Find the marker for this drop and highlight it
        const marker = markerRefs.current[dropId];
        if (marker) {
            const el = marker.getElement();
            el.classList.add('highlighted-marker');

            // Show the popup for this marker
            marker.togglePopup();
        }
    };

    // Handle hover end - remove highlight from the marker
    const handleHoverEnd = () => {
        const previousDropId = hoveredDropId;
        setHoveredDropId(null);

        // Reset the previously highlighted marker
        if (previousDropId) {
            const marker = markerRefs.current[previousDropId];
            if (marker) {
                const el = marker.getElement();
                el.classList.remove('highlighted-marker');

                // Hide the popup
                marker.togglePopup();
            }
        }
    };

    useEffect(() => {
        if (tripData) {
            console.log('TripModal opened with new data, refreshing branch location');

            // Add this debug info
            console.log('Current localStorage values:', {
                lat: localStorage.getItem('branch_lat'),
                lng: localStorage.getItem('branch_lng'),
                postcode: localStorage.getItem('branch_postcode')
            });

            // Update branch location
            setBranchLocation(getBranchLocation());
        }
    }, [tripData]);

    useEffect(() => {
        console.log('Branch location changed:', branchLocation);

        // If the map is already initialized, we need to recreate it
        if (map.current && mapContainer.current) {
            console.log('Recreating map with new center');

            // Remove existing map
            map.current.remove();
            map.current = null;

            // Create new map with updated center
            map.current = new mapboxgl.Map({
                container: mapContainer.current,
                style: 'mapbox://styles/mapbox/streets-v11',
                center: [branchLocation.lng, branchLocation.lat],
                zoom: 11
            });

            // Reinitialize map with same setup as before
            // Add CSS for markers
            const style = document.createElement('style');
            style.textContent = `
            .branch-marker {
              width: 40px;
              height: 40px;
              cursor: pointer;
              z-index: 100;
            }
            .drop-marker {
              border: 2px solid white;
              box-shadow: 0 2px 4px rgba(0,0,0,0.2);
            }
            .mapboxgl-popup-content {
              padding: 10px;
              border-radius: 6px;
              box-shadow: 0 2px 10px rgba(0,0,0,0.1);
            }
          `;
            document.head.appendChild(style);

            map.current.on('load', () => {
                setLoading(prev => ({ ...prev, map: false }));

                // Add branch marker
                const branchEl = document.createElement('div');
                branchEl.className = 'branch-marker';
                branchEl.innerHTML = `
              <div class="flex items-center justify-center bg-white rounded-full p-1 shadow-md">
                <img 
                  src="${apir}/public/images/logo.jpg" 
                  alt="Branch" 
                  class="w-8 h-8 object-contain rounded-full"
                />
              </div>
            `;

                new mapboxgl.Marker({ element: branchEl, anchor: 'center' })
                    .setLngLat([branchLocation.lng, branchLocation.lat])
                    .setPopup(new mapboxgl.Popup().setHTML(`<strong>Branch</strong><br>${branchLocation.postcode}`))
                    .addTo(map.current);

                // When map is loaded and we have drops, update the route
                if (drops.length > 0) {
                    updateRoute(drops);
                }
            });
        }
    }, [branchLocation]); // This effect depends on branchLocation

    // Initialize drops from trip data
    useEffect(() => {
        if (tripData) {
            // Transform invoices into drops
            const groupedByCustomer = {}
            console.info('tripData', tripData)
            tripData.Invoices.forEach(invoice => {
                if (!groupedByCustomer[invoice.Acct]) {
                    // Use the actual location from the invoice data
                    console.log('Invoice data:', invoice);

                    let location = null;
                    if (invoice.Loc && invoice.Loc.length > 0 &&
                        typeof invoice.Loc[0].lat === 'number' &&
                        typeof invoice.Loc[0].lon === 'number') {
                        location = {
                            lat: invoice.Loc[0].lat,
                            lng: invoice.Loc[0].lon
                        };
                        console.log('Extracted location:', location);
                    }

                    groupedByCustomer[invoice.Acct] = {
                        id: `drop-${invoice.Acct}`,
                        acct: invoice.Acct,
                        name: invoice.Name,
                        itemCount: 0,
                        documents: [],
                        location: location
                    }
                }

                const itemCount = parseInt(invoice.ItemsQty || 0)
                groupedByCustomer[invoice.Acct].itemCount += itemCount

                // Example item data - in real implementation, you would fetch the actual items
                const sampleItems = Array.from({ length: Math.min(5, itemCount) }, (_, i) => ({
                    description: `Sample Item ${i + 1}`,
                    quantity: Math.ceil(Math.random() * 3)
                }))

                groupedByCustomer[invoice.Acct].documents.push({
                    id: invoice.Document,
                    items: sampleItems
                })
            })

            setDrops(Object.values(groupedByCustomer))

            // Calculate estimated finish time
            const departureTime = moment(tripData.DepartureTime)
            // Simple estimation: 20 mins per drop + 30 mins back to branch
            const estimatedDuration = (Object.keys(groupedByCustomer).length * 20) + 30
            const finishTime = departureTime.clone().add(estimatedDuration, 'minutes')
            setEstimatedFinishTime(finishTime.format('HH:mm'))
        }
    }, [tripData])

    // Initialize Mapbox
    useEffect(() => {
        if (!map.current && mapContainer.current) {
            // Validate branch location
            console.log('Initializing map with center:', branchLocation);

            map.current = new mapboxgl.Map({
                container: mapContainer.current,
                style: 'mapbox://styles/mapbox/streets-v11',
                center: [branchLocation.lng, branchLocation.lat],
                zoom: 11
            })

            // Add CSS for markers
            const style = document.createElement('style')
            style.textContent = `
        .branch-marker {
          width: 40px;
          height: 40px;
          cursor: pointer;
          z-index: 100;
        }
        .drop-marker {
          border: 2px solid white;
          box-shadow: 0 2px 4px rgba(0,0,0,0.2);
        }
        .mapboxgl-popup-content {
          padding: 10px;
          border-radius: 6px;
          box-shadow: 0 2px 10px rgba(0,0,0,0.1);
        }
      `
            document.head.appendChild(style)

            map.current.on('load', () => {
                setLoading(prev => ({ ...prev, map: false }))

                // Add branch marker when map loads
                const branchEl = document.createElement('div')
                branchEl.className = 'branch-marker'
                branchEl.innerHTML = `
          <div class="flex items-center justify-center bg-white rounded-full p-1 shadow-md">
            <img 
              src="${apir}/public/images/logo.jpg" 
              alt="Branch" 
              class="w-8 h-8 object-contain rounded-full"
            />
          </div>
        `

                new mapboxgl.Marker({ element: branchEl, anchor: 'center' })
                    .setLngLat([branchLocation.lng, branchLocation.lat])
                    .setPopup(new mapboxgl.Popup().setHTML(`<strong>Branch</strong><br>${branchLocation.postcode}`))
                    .addTo(map.current)

                // When map is loaded and we have drops, update the route
                if (drops.length > 0) {
                    updateRoute(drops)
                }
            })
        }

        return () => {
            if (map.current) {
                map.current.remove()
                map.current = null
            }
        }
    }, [mapContainer.current])

    // Update route when drops change
    useEffect(() => {
        if (map.current && drops.length > 0 && !loading.map) {
            // Filter out drops without location data
            const dropsWithLocation = drops.filter(drop => drop.location);
            if (dropsWithLocation.length > 0) {
                updateRoute(dropsWithLocation);
            }
        }
    }, [drops, loading.map])

    // Generate and update route - Modified to store marker references
    const updateRoute = async (currentDrops) => {
        // Clear existing markers except branch
        const markers = document.querySelectorAll('.mapboxgl-marker:not(.branch-marker)')
        markers.forEach(marker => {
            marker.remove()
        })

        // Reset marker references
        markerRefs.current = {};

        // FIXED: Properly check and remove existing route layer and source
        if (map.current.getStyle()) {
            if (map.current.getLayer('route')) {
                map.current.removeLayer('route')
            }

            if (map.current.getSource('route')) {
                map.current.removeSource('route')
            }
        }

        if (currentDrops.length === 0) return

        setLoading(prev => ({ ...prev, route: true }))

        try {
            // Clear existing markers except branch
            const markers = document.querySelectorAll('.mapboxgl-marker:not(.branch-marker)')
            markers.forEach(marker => {
                marker.remove()
            })

            try {
                // First try to remove the layer
                if (map.current.getLayer('route')) {
                    map.current.removeLayer('route')
                }
            } catch (e) {
                // Silently handle if the layer doesn't exist
            }

            try {
                // Then try to remove the source
                if (map.current.getSource('route')) {
                    map.current.removeSource('route')
                }
            } catch (e) {
                // Silently handle if the source doesn't exist  
            }

            // Add markers for each drop
            currentDrops.forEach((drop, index) => {
                if (!drop.location) return;

                const el = document.createElement('div')
                el.className = 'flex items-center justify-center bg-red-500 text-white rounded-full w-6 h-6 font-bold text-sm drop-marker'
                el.innerHTML = (index + 1).toString()

                const popup = new mapboxgl.Popup({ offset: 25 }).setHTML(`
                    <div class="p-2">
                        <strong>${drop.name}</strong><br>
                        Account: ${drop.acct}<br>
                        <span class="text-sm text-gray-500">
                            ETA: ${estimateArrivalTime(index, tripData.DepartureTime)}
                        </span>
                    </div>
                `);

                // Create the marker with popup but don't show popup initially
                const marker = new mapboxgl.Marker({ element: el })
                    .setLngLat([drop.location.lng, drop.location.lat])
                    .setPopup(popup)
                    .addTo(map.current);

                // Store reference to this marker for hover functionality
                markerRefs.current[drop.id] = marker;
            })

            // Use Mapbox Directions API for actual routing
            // Create waypoints array starting with branch, then all drops, then branch again
            const waypoints = [
                [branchLocation.lng, branchLocation.lat],
                ...currentDrops.map(drop => [drop.location.lng, drop.location.lat]),
                [branchLocation.lng, branchLocation.lat]
            ]

            // Create API requests for each segment of the route
            const routeSegments = []
            for (let i = 0; i < waypoints.length - 1; i++) {
                const start = waypoints[i]
                const end = waypoints[i + 1]

                routeSegments.push(
                    fetch(`https://api.mapbox.com/directions/v5/mapbox/driving/${start[0]},${start[1]};${end[0]},${end[1]}?steps=true&geometries=geojson&access_token=${MAPBOX_ACCESS_TOKEN}`)
                        .then(response => response.json())
                )
            }

            // Wait for all route segment requests to complete
            const segmentResponses = await Promise.all(routeSegments)

            // Combine all route geometries
            const combinedRoute = {
                type: 'Feature',
                properties: {},
                geometry: {
                    type: 'LineString',
                    coordinates: []
                }
            }

            segmentResponses.forEach(response => {
                if (response.routes && response.routes.length > 0) {
                    const coordinates = response.routes[0].geometry.coordinates
                    combinedRoute.geometry.coordinates = [
                        ...combinedRoute.geometry.coordinates,
                        ...(combinedRoute.geometry.coordinates.length ? coordinates.slice(1) : coordinates)
                    ]
                }
            })

            // Add route to map
            try {
                // Double-check again that the source doesn't exist before adding
                if (!map.current.getSource('route')) {
                    map.current.addSource('route', {
                        type: 'geojson',
                        data: combinedRoute
                    })
                } else {
                    // If it somehow still exists, try to update it instead
                    const source = map.current.getSource('route');
                    source.setData(combinedRoute);
                }
            } catch (e) {
                console.error('Error adding/updating route source:', e);
            }

            try {
                // Check if the layer already exists
                if (!map.current.getLayer('route')) {
                    map.current.addLayer({
                        id: 'route',
                        type: 'line',
                        source: 'route',
                        layout: {
                            'line-join': 'round',
                            'line-cap': 'round'
                        },
                        paint: {
                            'line-color': '#3182ce',
                            'line-width': 4
                        }
                    });
                }
            } catch (e) {
                console.error('Error adding route layer:', e);
            }

            // Fit map to include all points
            const bounds = new mapboxgl.LngLatBounds();

            // Add all waypoints to the bounds
            waypoints.forEach(coord => {
                bounds.extend(coord);
            });

            // Add all route coordinates to ensure the entire path is visible
            if (combinedRoute.geometry.coordinates.length > 0) {
                combinedRoute.geometry.coordinates.forEach(coord => {
                    bounds.extend(coord);
                });
            }

            // Set more generous padding to ensure route is fully visible
            map.current.fitBounds(bounds, {
                padding: {
                    top: 100,
                    bottom: 100,
                    left: 100,
                    right: 100
                },
                maxZoom: 12 // Limit how far the map can zoom in
            });
            setRoute(combinedRoute)
        } catch (error) {
            console.error('Error updating route:', error)
        } finally {
            setLoading(prev => ({ ...prev, route: false }))
        }
    }

    const estimateArrivalTime = (dropIndex, departureTime) => {
        const base = moment(departureTime);
        const minutesToAdd = 15 + (dropIndex * 20);
        return base.clone().add(minutesToAdd, 'minutes').format('HH:mm');
    };

    // Auto-route optimization
    const handleAutoRoute = async () => {
        setLoading(prev => ({ ...prev, autoRoute: true }));

        try {
            // This is where you would call a route optimization service
            // For this example, we'll just randomize the order as a placeholder
            const optimizedDrops = [...drops];
            for (let i = optimizedDrops.length - 1; i > 0; i--) {
                const j = Math.floor(Math.random() * (i + 1));
                [optimizedDrops[i], optimizedDrops[j]] = [optimizedDrops[j], optimizedDrops[i]];
            }

            // Update drops with optimized order
            setDrops(optimizedDrops);

            // Recalculate the estimated finish time based on the optimized route
            const newFinishTime = recalculateEstimatedFinishTime(optimizedDrops, tripData.DepartureTime);
            setEstimatedFinishTime(newFinishTime);
        } catch (error) {
            console.error('Error optimising route:', error);
        } finally {
            setLoading(prev => ({ ...prev, autoRoute: false }));
        }
    };

    const handleDragEnd = (event) => {
        const { active, over } = event;

        if (active.id !== over.id) {
            const oldIndex = drops.findIndex(item => item.id === active.id);
            const newIndex = drops.findIndex(item => item.id === over.id);

            const newItems = [...drops];
            const [movedItem] = newItems.splice(oldIndex, 1);
            newItems.splice(newIndex, 0, movedItem);

            // ✅ Set state and THEN run logic
            setDrops(newItems);

            // ✅ Delay logic so it uses updated drops (DOM updates on next tick)
            setTimeout(() => {
                const newFinishTime = recalculateEstimatedFinishTime(newItems, tripData.DepartureTime);
                setEstimatedFinishTime(newFinishTime);
                updateRoute(newItems);
            }, 0);
        }
    };

    // Toggle drop expansion
    const handleExpandToggle = (dropId) => {
        console.log('Toggle expansion for drop:', dropId);

        // Force a re-render by creating a new array
        setExpandedDrops(prev => {
            const isCurrentlyExpanded = prev.includes(dropId);
            if (isCurrentlyExpanded) {
                return prev.filter(id => id !== dropId);
            } else {
                return [...prev, dropId];
            }
        });
    };

    // Add collection to the route
    const handleAddCollection = (collection) => {
        // Add the collection as a new drop
        const newCollection = {
            id: `collection-${Date.now()}`,
            acct: collection.customerAcct,
            name: collection.customerName,
            itemCount: 0,
            documents: [{
                id: `C-${Date.now()}`,
                items: []
            }],
            isCollection: true,
            collectionType: collection.reason,
            notes: collection.notes,
            location: {
                lat: 51.5 + (Math.random() * 0.1),
                lng: -0.1 - (Math.random() * 0.1)
            }
        };

        const updatedDrops = [...drops, newCollection];
        setDrops(updatedDrops);

        // Automatically expand the new collection
        setExpandedDrops(prev => [...prev, newCollection.id]);

        // Recalculate finish time with the new collection added
        const newFinishTime = recalculateEstimatedFinishTime(updatedDrops, tripData.DepartureTime);
        setEstimatedFinishTime(newFinishTime);
    };
    const generateGuid = () => {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    };

    // Assign to vehicle
    const handleAssignToVehicle = async () => {
        if (!selectedVehicle) {
            alert('Please select a vehicle first');
            return;
        }

        try {
            // Show loading state
            setAssignLoading(true);

            const selectedVehicleData = vehicles.find(v => v.id === selectedVehicle);
            console.log(`Assigning trip ${tripData._id} to vehicle ${selectedVehicle}`, selectedVehicleData);

            // FIRST CHECK: Check if the vehicle already has an active run
            console.log('Checking for existing runs for vehicle:', selectedVehicleData.reg);
            try {
                const checkResponse = await fetch(`${apir}/run/checkActiveRunByUser/${selectedVehicleData.reg}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });

                const checkData = await checkResponse.json();

                // If there are existing runs for this vehicle, notify the user and abort
                if (checkData && checkData.length > 0) {
                    console.log('Existing runs found:', checkData);
                    alert(`This vehicle (${selectedVehicleData.reg}) already has an active run. Please complete or delete the existing run before assigning a new one.`);
                    setAssignLoading(false);
                    return;
                }

                console.log('No existing runs found for this vehicle. Proceeding with assignment.');
            } catch (checkError) {
                console.error('Error checking for existing runs:', checkError);
                // If we can't check, we'll continue with the assignment
                console.log('Continuing with assignment despite check error');
            }

            // Generate a SINGLE RunId for the entire run
            const runGuid = uuid();
            console.log('Generated single RunId for all drops:', runGuid);

            // Get a clean branch postcode
            let branchPostcode = localStorage.getItem('pcode') || "";
            branchPostcode = branchPostcode.replace(/"/g, '');

            // Get branch location for posting to location table
            const branchLoc = getBranchLocation();

            // STEP TWO: Add an entry to the location table with the RunId as Driver
            console.log('Posting initial location for RunId:', runGuid);
            try {
                const locationResponse = await fetch(`${apir}/driver/addLocation`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        DateTime: new Date().toISOString(),
                        Driver: runGuid, // Use RunId as Driver
                        Location: { lat: branchLoc.lat, lon: branchLoc.lng },
                        User: selectedVehicleData.reg,
                        RunId: runGuid
                    })
                });

                if (!locationResponse.ok) {
                    const errorText = await locationResponse.text();
                    console.error('Error posting to location table:', errorText);
                    throw new Error(`Failed to post location: ${locationResponse.status}`);
                }

                console.log('Successfully posted initial location');
            } catch (locationError) {
                console.error('Error posting to location table:', locationError);
                alert('Failed to initialize location for this run. Please try again.');
                setAssignLoading(false);
                return;
            }

            // Create a new array for the active runs
            let activeRuns = [];

            // Add each drop to the array
            for (let i = 0; i < drops.length; i++) {
                const drop = drops[i];
                const documents = drop.documents.map(doc => doc.id);

                // Find the matching invoice to get address/postcode
                const matchingInvoice = tripData.Invoices.find(inv => inv.Acct === drop.acct);

                // Create a new drop object - use RunId as the Driver value
                const newDrop = {
                    DdId: tripData._id,
                    Date: new Date().toISOString(),
                    Driver: runGuid, // Use RunId as the Driver value
                    Document: documents,
                    Acct: drop.acct,
                    Name: drop.name,
                    Address: matchingInvoice?.Addra || "",
                    Pcode: matchingInvoice?.PCode || "",
                    Status: "Waiting",
                    Eta: 0,
                    DropId: uuid(), // Each drop gets a unique DropId
                    Loc: drop.location ? [
                        {
                            lat: drop.location.lat,
                            lon: drop.location.lng
                        }
                    ] : [],
                    User: selectedVehicleData.reg,
                    RunId: runGuid, // USING THE SAME RunId for all drops
                    Branch: branch,
                    // Set the Type field based on whether it's a collection or standard drop
                    Type: drop.isCollection ? "Collection" : "Drop"
                };

                // Add to the array
                activeRuns.push(newDrop);
            }

            // Add return journey
            activeRuns.push({
                DdId: tripData._id,
                Date: new Date().toISOString(),
                Driver: runGuid, // Use RunId as the Driver value
                Document: ["none"],
                Acct: "|none|",
                Name: "RETURN JOURNEY",
                Address: "Back to Start Point",
                Pcode: branchPostcode,
                Status: "Waiting",
                Eta: 0,
                DropId: uuid(),
                Loc: [{
                    lat: branchLoc.lat,
                    lon: branchLoc.lng
                }],
                User: selectedVehicleData.reg,
                RunId: runGuid, // USING THE SAME RunId as all other drops
                Branch: branch,
                Type: "Return"
            });

            console.log('API URL:', `${apir}/run/addActiveRun`);
            console.log('Sending data:', JSON.stringify(activeRuns, null, 2));

            // THIRD STEP: After posting to location, now we can add to activeRun
            const response = await fetch(`${apir}/run/addActiveRun`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(activeRuns)
            });

            if (!response.ok) {
                const errorText = await response.text();
                console.error('API error status:', response.status);
                console.error('API error text:', errorText);
                throw new Error(`API error: ${response.status}`);
            }

            const data = await response.json();
            console.log('API Response:', data);

            // Show success message
            alert(`Trip assigned to ${selectedVehicleData.reg} (${selectedVehicleData.make} ${selectedVehicleData.model})`);

            // Close modal
            modal.remove();

        } catch (error) {
            console.error('Error assigning trip:', error);
            alert(`Error assigning trip: ${error.message}`);
        } finally {
            setAssignLoading(false);
        }
    };



    if (!tripData) return null

    return (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
            <Card className="w-11/12 h-5/6 max-w-7xl max-h-[90vh] flex flex-col">
                {/* Header */}
                <div className="flex justify-between items-center px-4 py-3 border-b border-gray-200">
                    <div className="flex items-center space-x-4">
                        <h2 className="text-xl font-bold">{tripData.RunName}</h2>
                        <div className="flex items-center space-x-2">
                            <span className="text-gray-500">Departs:</span>
                            <span className="font-medium">{moment(tripData.DepartureTime).format('HH:mm DD/MM/YYYY')}</span>
                        </div>
                        <div className="flex items-center space-x-2">
                            <span className="text-gray-500">Est. Finish:</span>
                            <span className="font-medium">{estimatedFinishTime}</span>
                        </div>
                    </div>

                    <div className="flex items-center space-x-4">
                        <div className="flex items-center space-x-2">
                            <Badge variant="blue" rounded>{drops.length}</Badge>
                            <span>Drops</span>
                        </div>
                        <div className="flex items-center space-x-2">
                            <Badge variant="green" rounded>{tripData.Invoices.length}</Badge>
                            <span>Docs</span>
                        </div>
                        <div className="flex items-center space-x-2">
                            <Badge variant="orange" rounded>{tripData.LinesQty - 1}</Badge>
                            <span>Items</span>
                        </div>
                        <Button
                            variant="rounded"
                            onClick={() => modal.remove()}
                            Icon={XMarkIcon}
                        />
                    </div>
                </div>

                {/* Content: Split View */}
                <div className="flex flex-1 overflow-hidden">
                    {/* Left panel: 1/3 width */}
                    <div className="w-1/3 border-r border-gray-200 flex flex-col">
                        <div className="flex-1 overflow-y-auto p-3">
                            <h3 className="text-sm font-bold uppercase text-gray-500 mb-3">Delivery Route</h3>

                            <DndContext
                                sensors={useSensors(useSensor(MouseSensor), useSensor(TouchSensor))}
                                collisionDetection={closestCenter}
                                onDragEnd={handleDragEnd}
                                modifiers={[restrictToVerticalAxis]}
                            >
                                <SortableContext items={drops.map(drop => drop.id)} strategy={verticalListSortingStrategy}>
                                    {drops.map((drop, index) => (
                                        <SortableDropItem
                                            key={drop.id}
                                            drop={drop}
                                            index={index}
                                            expanded={expandedDrops.includes(drop.id)}
                                            onExpandToggle={handleExpandToggle}
                                            onHoverStart={handleHoverStart}
                                            onHoverEnd={handleHoverEnd}
                                            invoiceDetails={invoiceDetails}
                                            fetchInvoiceDetails={fetchInvoiceDetails}
                                        />
                                    ))}
                                </SortableContext>
                            </DndContext>

                            {drops.length === 0 && (
                                <div className="text-center py-8 text-gray-500">
                                    No drops assigned to this trip
                                </div>
                            )}
                        </div>

                        <div className="p-3 border-t border-gray-200 space-y-3">
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                <Button
                                    variant="outline"
                                    className="w-full"
                                    onClick={() => NiceModal.show(CollectionModal, {
                                        tripId: tripData._id,
                                        onAddCollection: handleAddCollection
                                    })}
                                >
                                    Add Collection 📥
                                </Button>
                                <Button
                                    variant="secondary"
                                    className="w-full"
                                    onClick={handleAutoRoute}
                                    loading={loading.autoRoute}
                                    Icon={ArrowPathIcon}
                                >
                                    Auto-Route Optimisation
                                </Button>
                            </div>

                            <div className="flex space-x-2">
                                <select
                                    className="flex-1 rounded-md border border-gray-300 p-2"
                                    value={selectedVehicle}
                                    onChange={(e) => setSelectedVehicle(e.target.value)}
                                    disabled={loadingVehicles}
                                >
                                    <option value="">{loadingVehicles ? 'Loading vehicles...' : 'Select Vehicle'}</option>
                                    {vehicles.map(vehicle => (
                                        <option key={vehicle.id} value={vehicle.id}>
                                            {vehicle.reg} - {vehicle.make} {vehicle.model} ({vehicle.color})
                                        </option>
                                    ))}
                                </select>

                                <Button
                                    variant="primary"
                                    disabled={!selectedVehicle || assignLoading}
                                    onClick={handleAssignToVehicle}
                                    loading={assignLoading}
                                    Icon={TruckIcon}
                                >
                                    Assign
                                </Button>

                            </div>



                            <Button
                                variant="secondary"
                                className="w-full"
                                onClick={() => modal.remove()}
                            >
                                Close
                            </Button>
                        </div>
                    </div>

                    {/* Right panel: 2/3 width */}
                    <div className="w-2/3 relative">
                        <div ref={mapContainer} className="w-full h-full" />

                        {loading.map && (
                            <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-75">
                                <div className="text-center">
                                    <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500 mb-2"></div>
                                    <p className="text-gray-700">Loading map...</p>
                                </div>
                            </div>
                        )}

                        {loading.route && (
                            <div className="absolute bottom-4 right-4 bg-white p-2 rounded shadow">
                                <div className="flex items-center space-x-2">
                                    <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-blue-500"></div>
                                    <span className="text-sm text-gray-700">Updating route...</span>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </Card>
        </div>
    )
})

export default TripModal